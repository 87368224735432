<template>
  <div class="body">
    <div
        class="flex_able margin-top40 margin-right30 line-height42 font-size30 font-color1B1B1B margin-left30">
      <span class="font-weightBold" style="flex: 1">数量</span>
      <span @click="openTopUpPowerRecord" class="font-size30" style="color: #268DFF">记录</span>
    </div>
    <div class="flex_able"
         style="background:#FAFAFA;margin-top: var(--38);border-radius: var(--24);height: var(--112);
        margin-left: var(--30);margin-right: var(--30);padding-left: var(--30);padding-right: var(--30);">
      <van-field style="flex:1;margin-right: var(--30);" v-model="addressValue" :placeholder=minAmountStr clearable/>
      <span class="font-size28 font-color1B1B1B line-height40">X币</span>
    </div>
    <div v-if="errorMsg.length > 0" style="color:#F35757;margin-top: var(--18);"
         class="font-size26 line-height36 margin-left30">
      {{ errorMsg }}
    </div>
    <div class="flex_center" style="margin-top: var(--94)">
      <div @click="submitInfo" class="flex_center"
           :style="{'background': (isEmpty(errorMsg) && !isEmpty(addressValue)) ? '#268DFF' : '#BEDDFF'}"
           style="width: var(--690);height: var(--88);
          border-radius: var(--44);font-size: var(--30);font-weight: 400;color: #FFFFFF;">
        确认充值
      </div>
    </div>
    <div class="font-weightBold"
         style="margin-left: var(--30);margin-top: var(--84);font-size: var(--30);color: #1b1b1b;line-height: var(--42);">
      规则：
    </div>
    <!-- 条件内容 -->
    <div style="margin-left: var(--30);margin-top: var(--16);color: #666666"
         class="participation_conditions_box_content"
         v-html="rewardRole">

    </div>
  </div>
</template>

<script>
//开始1
import {Toast} from 'vant';
import {Api} from '@/utils/net/Api';
import {Constants} from "@/utils/constants";
import EncryptUtils from "../../../utils/EncryptUtils";
// 定义属性和变量
let pressTimer = null;
//开始2
export default {
  data() {
    //开始4
    //初始化数据
    return {
      timeNumber: 1,
      actionId: "",
      errorMsg: "",
      rewardRole: '',
      balanceX: 0,
      minAmount: 0,
      maxAmount: 0,
      minAmountStr: '',
      addressValue: '',
      pressTimer: {},
    }
  },
  created() {
    //开始5
    try {
      //设置标题
      document.title = "充值"
    } catch (error) {
      console.log("常规错误:" + error)
    }
    this.queryWalletRechargeConfig()
  },
  watch: {
    addressValue(val, newVal) {
      console.log("val" + val)
      console.log("newVal" + newVal)
      if (val < this.minAmount) {
        this.errorMsg = "最小起充数量为" + this.minAmount + "X币"
      } else {
        this.errorMsg = ""
      }
      console.log(this.rate)
      console.log(this.balanceX)
      this.needPayAmount = this.addressValue * this.rate
      console.log(this.needPayAmount)
    }
  },
  methods: {
    isEmpty(obj) {
      return typeof obj == "undefined" || obj == null || obj == "";
    },
    openTopUpPowerRecord() {
      this.$router.push({
        path: '/topUpPowerRecord',
      });
    },
    handler() {
      this.timeNumber++
      if (this.timeNumber > 300) {
        //取消
        this.cancel()
      } else {
        this.checkPayResult()
      }
    },
    //开始定时器
    start() {
      //开启loading
      // Toast.loading({
      //   forbidClick: true,
      //   duration: 0
      // });
      if (pressTimer === null) {
        pressTimer = setInterval(() => {
          // 执行函数
          this.handler();
        }, 1000);
      }
    },
    // 取消计时器
    cancel() {
      // Toast.clear()
      //恢复
      this.timeNumber = 1
      // 检查计时器是否有值
      if (pressTimer !== null) {
        clearInterval(pressTimer);
        pressTimer = null;
      }
    },
    checkPayResult() {
      let params = {
        actionId: this.actionId,
      }
      Api.tpPayPoll(params).then((result) => {
        console.log('data....', result)
        if (result.code === 100) {
          try {
            let resultTemp = result.data.result
            let txID = result.data.txID
            if (resultTemp == 1 && !this.isEmpty(txID)) {
              Toast("处理中")
              //跳转到记录页面
              this.openTopUpPowerRecord()
              //只有成功取消
              this.cancel()
            }
          } catch (error) {
            console.log("常规错误:" + error)
          }
        } else {
          Toast(result.msg);
        }
      })
    },
    //提取大链上
    submitInfo() {
      //判断地址
      if (!this.isEmpty(this.errorMsg)) {
        return
      }
      if (this.isEmpty(this.addressValue)) {
        return
      }
      this.cancel()
      //先重建订单
      this.addXValueOrder()
    },
    addXValueOrder() {
      let params = {
        fromAssetType: Constants.X_COIN,
        toAssetType: Constants.X_COIN,
        amount: this.addressValue,
      }
      Api.addXValueOrder(params).then((result) => {
        if (result.code === 100) {
          let orderId = result.data.entity.id
          this.payXValueOrder(orderId)
        } else {
          Toast(result.msg);
        }
      })
    },
    queryPowerAccount() {
      let params = {
        assetType: Constants.X_COIN,
      }
      let that = this;
      Api.queryPowerAccount(params).then((result) => {
        if (result.code === 100) {
          that.balanceX = result.data?.accountVo?.balance
        } else {
          Toast(result.msg);
        }
      })
    },
    queryWalletRechargeConfig() {
      let data = {
        fromAssetType: Constants.X_COIN,
        toAssetType: Constants.X_COIN,
      }
      Api.queryWalletRechargeConfig(data).then(res => {
        if (res.code === 100) {
          console.log(res)
          EncryptUtils.formatDecimal(0.10)
          this.minAmount = res.data.entity.minAmount
          this.maxAmount = res.data.entity.maxAmount
          this.minAmountStr = this.minAmount + "X币起充"
          let activityRole = res.data?.entity.confDes
          this.rewardRole = activityRole?.replace(/\n/g, "<br>")
          //请求余额
          this.queryPowerAccount()
        } else {
          Toast(res.msg);
        }
      })
    },
    payXValueOrder(id) {
      console.log(id)
      let params = {
        fromAssetType: Constants.X_COIN,
        toAssetType: Constants.X_COIN,
        id: id,
        payType: 4,
      }
      Api.payXValueOrder(params).then((result) => {
        console.log('data....', result)
        if (result.code === 100) {
          let resultData = result.data.result
          this.actionId = resultData.actionId
          // let param = {
          //   callbackUrl: resultData.callbackUrl,
          //   actionId: resultData.actionId,
          //   amount: resultData.amount,
          //   chainId: resultData.chainId,
          //   blockchain: resultData.blockchain,
          //   dappIcon: resultData.dappIcon,
          //   dappName: resultData.dappName,
          //   desc: resultData.desc,
          //   to: resultData.to,
          //   precision: resultData.precision,
          //   symbol: resultData.symbol,
          //   contract: resultData.contract,
          // }
          // console.log(param)
          //下面数据是写死的  为了当时测试功能  后期是后端返回
          //下面是 深度链接demo
          let paramT = {
            "amount": resultData.amount,
            "contract": resultData.contract,
            "decimal": 18,
            "desc": resultData.desc,
            "from": "0x12F4900A1fB41f751b8F616832643224606B75B4",
            "memo": "",
            "precision": resultData.precision,
            "symbol": resultData.symbol,
            "to": resultData.to,
            "action": "transfer",
            "actionId": resultData.actionId,
            "blockchains": [{
              "chainId": resultData.chainId,
              "network": resultData.blockchain
            }],
            "dappIcon": resultData.dappIcon,
            "dappName": resultData.dappName,
            "protocol": "TokenPocket",
            "callbackUrl": resultData.callbackUrl,
            "version": "2.0"
          }
          console.log(JSON.stringify(paramT))
          window.location.href = "tpoutside://pull.activity?param=" + encodeURIComponent(JSON.stringify(paramT), "utf-8")
          this.start()
        } else {
          Toast(result.msg);
        }
      })
    },
  },
}
</script>

<style scoped>
.body {
  background: #ffffff;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* 设置背景和padding */
::v-deep .van-cell {
  background-color: transparent;
  padding: 0;
}

/* 设置 placeholder 颜色 */
::v-deep .van-field__control::placeholder {
  color: #7E90A0;
}

/* 输入框高度 增加热区 */
::v-deep .van-field__control {
  height: var(--112);
}

/* 设置field里面的字体大小 */
::v-deep .van-field__control {
  color: #1B1B1B;
  font-size: var(--28);
  font-weight: 400;
  line-height: var(--40);
}

::v-deep .van-cell::after {
  left: 0;
  border: none;
}

.participation_conditions_box_content {
  width: var(--692);
  line-height: 160%;
  font-size: var(--30);
  color: #C6C6C6;
}
</style>
